<template>
  <div class="product-container">
    <div class="product" :class="backgroundClass">
      <!-- <div
      class="header"
      v-if="product.headerText"
      :class="[
        product.id === 'product_103'
          ? 'sprite-profile-popup flama-orange'
          : 'sprite-profile-popup flama-green',
      ]"
    >
      <curved-text-svg :curvedText="product.headerText" class="header-text" />
    </div> -->
      <!-- <div class="sprite-profile-popup xp-flama">
      <span class="xp-amount"
        ><span>+ {{ product.xpAmount }} XP</span></span
      >
    </div> -->
      <div
        class="product-header sprite-profile-popup"
        v-if="headerClass"
        :class="headerClass"
      >
        <span class="product-header-text">
          {{ productDescription }}
        </span>
      </div>
      <div
        class="product-image"
        :style="`${
          calcProdIndex == 3 && platform == 'android' ? 'top:-20px;' : ''
        } ${calcProdIndex == 4 && platform == 'android' ? 'top:30px;' : ''} ${
          calcProdIndex == 6 && platform == 'android' ? 'top:-20px;' : ''
        }`"
        :class="`packet${calcProdIndex}`"
      ></div>
      <div class="product-price">
        <div class="sprite-profile-popup chip2"></div>
        <span class="price">{{ productChipAmount }}</span>
      </div>
      <div class="buy-button ui-button ui-button-green" @click="buy">
        <span class="product-money">
          {{ product.price }}
          <!-- {{ platform !== "facebook" ? product.currency : "" }} -->
        </span>
        <!-- <span class="price-fb" v-if="platform === 'facebook'"></span> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Products",
  components: {
    CurvedTextSvg: () => import("./component/CurvedTextSvg.vue"),
  },
  computed: {
    ...mapGetters(["getChipFormat"]),
    calcProdIndex() {
      if (typeof this.prodIndex == "number" && this.prodIndex > 0) {
        return this.prodIndex;
      }
      return 1;
    },
    productDescription() {
      if (this.platform === "facebook") {
        if (this.product.id == "52") {
          return "SPECIAL";
        } else if (this.product.id == "55") {
          return "BEST PRICE";
        }
      } else {
        return this.product.description;
      }
    },
    productChipAmount() {
      if (this.platform === "facebook") {
        return this.getChipFormat(this.product.chip);
      } else {
        return this.product.title;
      }
    },
    backgroundClass() {
      if (this.platform === "facebook") {
        if (this.product.id == "52") {
          return "purple";
        } else {
          return "red";
        }
      } else {
        if (this.product.id === "product_103") {
          return "purple";
        } else {
          return "red";
        }
      }
    },
    headerClass() {
      if (this.platform === "facebook") {
        if (this.product.id == "52") {
          return "flama-orange";
        } else if (this.product.id == "55") {
          return "flama-green";
        }
      } else {
        if (this.product.id === "product_103") {
          return "flama-orange";
        } else if (this.product.id === "product_106") {
          return "flama-green";
        }
      }
      return "";
    },
    // getProductImageClass() {
    //   return this.product.background === 0
    //     ? "sprite-profile-popup window1-image"
    //     : this.product.background === 1
    //     ? "sprite-profile-popup window2-image"
    //     : this.product.background === 2
    //     ? "sprite-profile-popup window3-image"
    //     : this.product.background === 3
    //     ? "sprite-profile-popup window4-image"
    //     : this.product.background === 4
    //     ? "sprite-profile-popup window5-image"
    //     : "sprite-profile-popup window6-image";
    // },
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    platform: {
      type: String,
      default: "",
    },
    prodIndex: {
      type: Number,
      default: -1,
    },
  },
  methods: {
    buy() {
      if (this.platform === "facebook") {
        this.$emit("buy", this.product.id);
      } else {
        this.$emit("buy", { ...this.product, platform: this.platform });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.product-container {
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product,
.platform-product {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  width: 369px;
  height: 373px;
  overflow: visible;
  transition: all 0.15s linear;

  // background: url("~@/assets/images/lobby/red-chip-bg.png");
  &.purple {
    background: url("~@/assets/images/shop-window/slot1.png");
    background-repeat: no-repeat;
    background-size: contain;
  }
  &.red {
    background: url("~@/assets/images/shop-window/slot2.png");
    background-repeat: no-repeat;
    background-size: contain;
  }
  // .xp-flama {
  //   position: absolute;
  //   right: 0;
  //   top: 40px;
  //   display: flex;
  //   justify-content: right;
  //   align-items: center;
  //   .xp-amount {
  //     width: 120px;
  //     font-size: 36px;
  //     font-weight: bold;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     text-align: center;
  //     text-shadow: 3px 3px 3px black;
  //   }
  // }
  &-header {
    position: relative;
    top: -65px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 34px;
    &-text {
      position: absolute;
      top: 35px;
      text-transform: uppercase;
      text-shadow: 3px 3px 3px black;
    }
  }
  &-image {
    position: relative;
  }
  .packet1 {
    top: 20px;
    width: 189px;
    height: 149px;
    background: url("~@/assets/images/shop-window/packet1.png");
    background-size: cover;
  }
  .packet2 {
    top: 10px;
    left: -10px;
    width: 215px;
    height: 190px;
    background: url("~@/assets/images/shop-window/packet2.png");
    background-size: cover;
  }
  .packet3 {
    top: 10px;
    width: 292px;
    height: 178px;
    background: url("~@/assets/images/shop-window/packet3.png");
    background-size: cover;
  }
  .packet4 {
    top: -60px;
    left: -10px;
    width: 323px;
    height: 134px;
    background: url("~@/assets/images/shop-window/packet4.png");
    background-size: cover;
  }
  .packet5 {
    top: 10px;
    width: 240px;
    height: 239px;
    background: url("~@/assets/images/shop-window/packet5.png");
    background-size: cover;
  }
  .packet6 {
    top: 30px;
    width: 270px;
    height: 142px;
    background: url("~@/assets/images/shop-window/packet6.png");
    background-size: cover;
  }
  &-price {
    position: absolute;
    bottom: 100px;
    width: 90%;
    height: 90px;
    border-radius: 10px;
    background: rgba(black, 0.5);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .price {
      text-align: center;
      width: 75%;
      font-size: 25px;
      color: #ffffff;
      text-transform: capitalize;
      background: -webkit-linear-gradient(#fefeb8, #d5ab48);
      text-shadow: 3px 2px 3px rgba(0, 0, 0, 0.2);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: bold;
    }
  }
  .buy-button {
    position: absolute;
    bottom: -8px;
    display: flex;
    flex-direction: row;
    width: 340px;
    .product-money {
      position: absolute;
      bottom: 40px;
    }
    .price-fb {
      background: url("~@/assets/images/table/dollar-image.png") no-repeat 50%
        50%;
      background-size: contain;
      width: 22px;
      height: 40px;
      position: relative;
      top: 1px;
      margin-left: 20px;
    }
  }
}
</style>
